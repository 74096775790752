<template>
  <nav id="sidebar" :class="colapseSidebar">
    <div class="custom-menu">
      <button
        type="button"
        id="sidebarCollapse"
        class="btn btn-primary"
        @click="colapse"
      >
        <i class="arrow fas" :class="arrow"></i>
      </button>
    </div>
    <div class="img bg-wrap text-center py-4" style="background-color:white;">
      <div class="user-logo">
        <router-link :to="$i18nRoute({ name: 'init' })">
          <img
            class="logowidth"
            src="@/assets/logo.png"
          />
        </router-link>
        <h3>{{ $t("Copla.Title") }} {{ version }}</h3>
      </div>
    </div>
    <LocaleSwitcher />
    <ul class="text-left list-unstyled components mb-5 mt-5 fa-ul">
      <li>
        <i class="fas fa-user"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'personalData' })">
          {{ $t("Copla.Menu.PersonalData") }}
        </router-link>
      </li>
      <li v-if="rol == 'ROLE_ADMIN'">
        <i class="fas fa-users"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'users' })">
          {{ $t("Copla.Menu.Users") }}
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_CESyA' && rol != 'ROLE_AD'">
        <i class="fas fa-table"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'statssrt' })">
          {{ $t("Copla.Menu.StatsSRT") }}
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_EXTERNAL' && rol != 'ROLE_CESyA' && rol != 'ROLE_AD'">
        <i class="fas fa-closed-captioning"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'subtitles' })">
          {{ $t("Copla.Menu.Subtitles") }}
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_EXTERNAL' && rol != 'ROLE_CESyA' && rol != 'ROLE_AD'">
        <i class="fas fa-sync-alt"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'sync' })">
          {{ $t("Copla.Menu.Sync") }}
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_EXTERNAL' && rol != 'ROLE_CESyA'">
        <i class="fas fa-audio-description"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'synthesizer' })">
          {{ $t("Copla.Menu.Synthesis") }}
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_EXTERNAL' && rol != 'ROLE_CESyA'">
        <i class="fas fa-audio-description"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'TeatroReal' })">
          Teatro Real
        </router-link>
      </li>
      <li v-if="rol != 'ROLE_EXTERNAL' && rol != 'ROLE_CESyA' && rol != 'ROLE_AD'">
        <i class="fas fa-list"></i>
        <router-link class="inline" :to="$i18nRoute({ name: 'applications' })">
          {{ $t("Copla.Menu.Applications") }}
        </router-link>
      </li>
    </ul>
    <button class="btn btn-danger" @click="logout">
      <i class="fas fa-sign-out-alt"></i>
      {{ $t("Copla.Menu.Logout") }}
    </button>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import LocaleSwitcher from "../components/LocaleSwitcher";
export default {
  data() {
    return {
      colapseSidebar: "",
      arrow: "fa-angle-double-right",
      version: process.env.VUE_APP_VERSION
    };
  },
  methods: {
    ...mapActions(["logout"]),
    colapse: function() {
      if (this.colapseSidebar == "active") {
        this.colapseSidebar = "";
        this.arrow = "fa-angle-double-right";
      } else {
        this.colapseSidebar = "active";
        this.arrow = "fa-angle-double-left";
      }
    }
  },
  components: {
    LocaleSwitcher
  },
  computed: {
    rol() {
      return this.$store.state.rol;
    }
  }
};
</script>

<style scoped>
.logowidth {
  max-width: 300px;
}
.inline {
  display: inline !important;
}
.arrow {
  margin-bottom: 3px;
  margin-left: -3px;
}
a:hover {
  text-decoration: none;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled li {
  height: 50px;
}
.bg-wrap {
  width: 100%;
  position: relative;
  z-index: 0;
}
.bg-wrap:after {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: #000;
  opacity: 0.3;
}
.bg-wrap .user-logo .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.bg-wrap .user-logo h3 {
  color: #fff;
  font-size: 18px;
}
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}
#sidebar {
  min-width: 300px;
  max-width: 300px;
  background: #32373d;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
#sidebar .h6 {
  color: #fff;
}
#sidebar.active {
  margin-left: -300px;
}
#sidebar.active .custom-menu {
  margin-right: -50px;
}
#sidebar.active .btn.btn-primary:after {
  display: none;
}
#sidebar h1 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
}
#sidebar h1 .logo {
  color: #fff;
  display: block;
  padding: 10px 30px;
  background: #7eb928;
}
#sidebar ul.components {
  padding: 0;
}
#sidebar ul li {
  font-size: 16px;
}
#sidebar ul li > ul {
  margin-left: 10px;
}
#sidebar ul li > ul li {
  font-size: 14px;
}
#sidebar ul li a {
  padding: 15px 30px;
  display: block;
  color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
#sidebar ul li a span.notif {
  position: relative;
}
#sidebar ul li a span.notif small {
  position: absolute;
  top: -3px;
  bottom: 0;
  right: -3px;
  width: 12px;
  height: 12px;
  content: "";
  background: red;
  border-radius: 50%;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 8px;
}
#sidebar ul li a:hover {
  color: #fff;
  background: #7eb928;
  border-bottom: 1px solid #7eb928;
}
#sidebar ul li.active > a {
  background: transparent;
  color: #fff;
}
#sidebar ul li.active > a:hover {
  background: #2f89fc;
  border-bottom: 1px solid #7eb928;
}
@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -300px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebar .custom-menu {
    margin-right: -60px !important;
    top: 10px !important;
  }
}
#sidebar .custom-menu {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -35px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  #sidebar .custom-menu {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
#sidebar .custom-menu .btn.btn-primary {
  background: #7eb928;
  border-color: transparent;
  position: relative;
  color: #000;
  width: 30px;
  height: 30px;
}
#sidebar .custom-menu .btn.btn-primary:after,
#sidebar .custom-menu .btn.btn-primary:before {
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: "FontAwesome";
  color: #fff;
}
</style>
